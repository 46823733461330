import React, { useState, useEffect, useRef } from "react";
import jsQR from "jsqr";
import "./QrScanner.css";

const QrScanner = () => {
  const [scannedUrl, setScannedUrl] = useState("");
  const [qrResult, setQrResult] = useState("");
  const [facingMode, setFacingMode] = useState("user");
  const [cameraOn, setCameraOn] = useState(false);
  const videoRef = useRef(null);

  const handleScan = (data) => {
    if (data) {
      setScannedUrl(data);
      window.open(data, "_blank");
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0, img.width, img.height);

          const imageData = context.getImageData(0, 0, img.width, img.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            setQrResult(code.data);
            window.open(code.data, "_blank");
          } else {
            alert("No QR code found in the image.");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) =>
      prevMode === "environment" ? "user" : "environment"
    );
  };

  const toggleCameraOn = () => {
    setCameraOn((prevState) => !prevState);
  };

  useEffect(() => {
    if (!cameraOn) {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
      return;
    }

    const constraints = {
      video: { facingMode: facingMode },
    };

    const currentVideoRef = videoRef.current;

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (currentVideoRef) {
          currentVideoRef.srcObject = stream;
        }

        const scanQRCode = () => {
          if (currentVideoRef) {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = currentVideoRef.videoWidth;
            canvas.height = currentVideoRef.videoHeight;
            context.drawImage(currentVideoRef, 0, 0, canvas.width, canvas.height);

            const imageData = context.getImageData(
              0,
              0,
              canvas.width,
              canvas.height
            );
            const code = jsQR(imageData.data, imageData.width, imageData.height);
            if (code) {
              handleScan(code.data);
            }
          }
          if (cameraOn) {
            requestAnimationFrame(scanQRCode);
          }
        };

        scanQRCode();
      })
      .catch((error) => {
        handleError(error);
      });

    return () => {
      if (currentVideoRef && currentVideoRef.srcObject) {
        currentVideoRef.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, [facingMode, cameraOn]);

  return (
    <div className="qr-scanner-container">
      <header className="header">
        <h1>QR Scanner</h1>
        <a href="https://rr-web.net/" className="home-link">
          Return to Home
        </a>
      </header>

      <section className="upload-section">
        <h2>Upload QR Code Image</h2>
        <p>How to Use:</p>
        <ul>
          <li>Click the "Upload Image with QR Code" button.</li>
          <li>Choose an image file containing a QR code.</li>
          <li>If a QR code is detected, the URL will be displayed, and you can click it to open the link in a new tab.</li>
        </ul>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {qrResult && (
          <p className="scanned-url">
            Scanned QR Code URL:{" "}
            <a href={qrResult} target="_blank" rel="noopener noreferrer">
              {qrResult}
            </a>
          </p>
        )}
      </section>

      <section className="camera-section">
        <h2>Scan with Camera</h2>
        <div className="button-group">
        <p>How to Use:</p>
        <ul>
          <li>Click the "Turn Camera On" button to activate the camera and scan the QR.</li>
          <li>If the QR code is detected, it will automatically extract the URL and open it in a new tab.</li>
          <li>Toggle Camera View: Use the "Switch Camera" button to toggle between the front-facing and rear-facing cameras.</li>
        </ul>
          <button onClick={toggleCameraOn} className="btn-toggle-camera">
            {cameraOn ? "Turn Camera Off" : "Turn Camera On"}
          </button>
          {cameraOn && (
            <button onClick={toggleCamera} className="btn-switch-camera">
              Switch Camera
            </button>
          )}
        </div>
        {cameraOn && (
          <div className="video-container">
            <video
              ref={videoRef}
              id="video"
              autoPlay
              muted
              playsInline
              className="video-feed"
            />
            {scannedUrl && (
              <p className="scanned-url">
                Scanned URL:{" "}
                <a href={scannedUrl} target="_blank" rel="noopener noreferrer">
                  {scannedUrl}
                </a>
              </p>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default QrScanner;
