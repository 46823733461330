import React from 'react';
import QrScanner from './QrScanner';
import Popup from './popup';

function App() {
  return (
    <div className="App">
      <QrScanner />
      <Popup/>
    </div>
  );
}

export default App;
